package ch.wsl.box.client.styles.fonts

import ch.wsl.box.client.styles.utils.StyleUtils
import scalacss.ProdDefaults._

object Font extends StyleSheet.Inline {

  var clearSans = StyleUtils.unsafeProp("font-family","Clear Sans")

}
